import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import Navbar from '../Navbar';
import ContactContent from '../ContactContent';

function Contact() {
  return (
    <>

      <Navbar />
      <ContactContent />
      <Footer />
    </>
  );
}

export default Contact;