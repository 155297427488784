import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import HeroSection from '../HeroSection';
import Footer from '../FooterCordoba';
import Navbar from '../NavbarCordoba';

function Cordoba() {
  return (
    <>

      <Navbar />
      <HeroSection />
      <Cards />
      <Footer />
    </>
  );
}

export default Cordoba;
