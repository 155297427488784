import React from 'react';
import '../App.css';
import { useHistory } from 'react-router-dom';
import { Button } from './Button';
import stylesh from './HeroSection1.module.css';

function HeroSection1() {
  const history = useHistory(); // Obtiene el objeto de historial

  const handleButtonClickCordoba = () => {
    // Realiza la redirección al hacer clic en el botón
    history.push('/cordoba');
  };
  const handleButtonClickHolding = () => {
    // Realiza la redirección al hacer clic en el botón
    history.push('/holding');
  };
  return (
    <div className={stylesh.herocontainer}>
      <div className={stylesh.videosection}>
        <div className={stylesh.videoContainer}>
          <video className={stylesh.video1} src='/videos/video-1.mp4' autoPlay loop muted />
        </div>
        <div className={stylesh.textContainer}>
          <h1 className={stylesh.h1hero1}>BCCY CORDOBA</h1>
          <div className={stylesh.herobtns}>
            <Button
              className={stylesh.btns}
              buttonStyle='btn--outline'
              buttonSize='btn--large'
              onClick={handleButtonClickCordoba}
            >
              INICIAR
            </Button>
          </div>
        </div>
      </div>
      <div className={stylesh.videosection}>
        <div className={stylesh.videoContainer}>
          <video className={stylesh.video1} src='/videos/video3.mp4' autoPlay loop muted />
        </div>
        <div className={stylesh.textContainer}>
          <h1 className={stylesh.h1hero1}>BCCY COLOMBIA HOLDING</h1>
          <div className={stylesh.herobtns}>
            <Button
              className={stylesh.btns}
              buttonStyle='btn--outline'
              buttonSize='btn--large'
              onClick={handleButtonClickHolding}
            >
              INICIAR
            </Button>
          </div>
        </div>
      </div>
    </div>
  );


}

export default HeroSection1;
