/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './Contact.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

function ContactContent() {

    const handleButtonYoutube = () => {
        window.open('https://www.youtube.com/channel/UCTBxxvzMkx8iEwuDEitBP8g', '_blank');
    };
    const handleButtonClick = () => {
        window.open('https://www.linkedin.com/company/bccy-colombia/jobs/?viewAsMember=true', '_blank');
    }

    const iconStyle = { color: 'black' };
    return (
        <div className="container1">
            <div className="box-info">
                <h1 className='h1contact'>CONTÁCTATE CON NOSOTROS</h1>
                <div className="data">
                    <p><FaPhone /> +57 601 756 9677</p>
                    <p><FaEnvelope /> bccycolombiasas@gmail.com</p>
                    <p><FaMapMarkerAlt /> Calle 98 # 9a 41</p>
                </div>
                <div className="links">
                    <a onClick={handleButtonYoutube}><FontAwesomeIcon icon={faYoutube} style={iconStyle} /></a>
                    <a onClick={handleButtonClick}><FontAwesomeIcon icon={faLinkedin} style={iconStyle} /></a>
                </div>
            </div>
            <form>
                <div className="input-box">
                    <input type="text" placeholder="Nombre y apellido" required />
                    <i className="fa-solid fa-user"></i>
                </div>
                <div className="input-box">
                    <input type="email" required placeholder="Correo electrónico" />
                    <i className="fa-solid fa-envelope"></i>
                </div>
                <div className="input-box">
                    <input type="text" placeholder="Asunto" />
                    <i className="fa-solid fa-pen-to-square"></i>
                </div>
                <div className="input-box">
                    <textarea placeholder="Escribe tu mensaje..."></textarea>
                </div>
                <button type="submit">Enviar mensaje</button>
            </form>
        </div>
    );
}

export default ContactContent;
