/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from 'react';
import './Contact.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faYoutube, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer } from "react-toastify";

function ContactContent() {

    const handleButtonYoutube = () => {
        window.open('https://www.youtube.com/channel/UCTBxxvzMkx8iEwuDEitBP8g', '_blank');
    };

    const handleButtonClick = () => {
        window.open('https://www.linkedin.com/company/bccy-colombia/jobs/?viewAsMember=true', '_blank');
    }

    const handleWhatsappButtonClick = () => {
        const whatsappNumber = '3160267867';
        const message = encodeURIComponent('Hola, estoy interesado en contactarme contigo.');
        window.open(`https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${message}`, '_blank');
    };

    const iconStyle = { color: 'black' };
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_8d3z17g', 'template_x7ggojh', form.current, {
                publicKey: 'i-bLSfevncm1AYF9N',
            })
            .then(
                () => {
                    toast.success('El correo se ha enviado satisfactoriamente.');
                    form.current.reset(); // Limpiar los campos del formulario después del envío
                },
                (error) => {
                    console.log('FAILED...', error.text);
                    toast.error('Ocurrió un error al enviar el correo.');
                }
            );
    };

    return (
        <div className="container1">
            <div className="box-info">
                <h1 className='h1contact'>CONTÁCTATENOS CÓRDOBA</h1>
                <div className="data">
                    <p><FaPhone /> +57 601 756 9677</p>
                    <p><FaEnvelope /> bccycolombiasas@gmail.com</p>
                    <p><FaMapMarkerAlt /> Calle 98 # 9a 41</p>
                </div>
                <div className="links">
                    <a onClick={handleButtonYoutube}><FontAwesomeIcon icon={faYoutube} style={iconStyle} /></a>
                    <a onClick={handleButtonClick}><FontAwesomeIcon icon={faLinkedin} style={iconStyle} /></a>
                    <a onClick={handleWhatsappButtonClick}><FontAwesomeIcon icon={faWhatsapp} style={iconStyle} /></a>
                </div>
            </div>

            <ToastContainer />
            <form ref={form} onSubmit={sendEmail} >
                <div className="input-box">
                    <input type="text" name="user_name" placeholder="Nombre y apellido"/>
                </div>
                <div className="input-box">
                    <input type="email" name="user_email"  placeholder="Correo electrónico"/>
                </div>
                <div className="input-box">
                    <textarea name="message" placeholder="Escribe tu mensaje..." />
                </div>
                <button type="submit" value="Send"> Enviar mensaje </button>
            </form>
        </div>
    );
}

export default ContactContent;
