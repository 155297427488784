import React from 'react';
import './Cards.css';

function CardsPro1() {
  return (
    <div className='cards'>
      <h1 className='h1cards'>Datos Clave Petalos de Cesar I</h1>
      <div className='cards__paragraphs'>
          <ul>
              <li>Localización: Municipio de Chiriguana (Cesar)</li>
              <li>Área del terreno: 33ha</li>
              <li>Capacidad: 9.9 MWac (13MWp)</li>
              <li>Producción: 28.9 GWh/Año (P50)</li>
              <li>Punto de conexión: Subestación Chiriguana 13.8 kv</li>
              <li>Línea de conexión: 1.25 km a 13.8KV</li>
              <li>Paneles Solares: 25.000 paneles LONGI Solar de 540Wp</li>
              <li>Inversores: 50 DE 200kw HUAWEI</li>
              <li>Entrada en Operación Comercial: 30 de Junio de 2024</li>
              <li>Estado: En diseño</li>
            </ul>
      </div>
    </div>
  );
}

export default CardsPro1;
