import React from 'react';
import '../../App.css';
import Footer from '../FooterCordoba';
import Navbar from '../NavbarCordoba';
import ContactContent from '../ContactContentC';

function ContactCordoba() {
  return (
    <>

      <Navbar />
      <ContactContent />
      <Footer />
    </>
  );
}

export default ContactCordoba;