import React from 'react';
import '../../App.css';
import Footer from '../FooterHolding';
import Navbar from '../NavbarHolding';
import ContactContent from '../ContactContentH';

function ContactHolding() {
  return (
    <>

      <Navbar />
      <ContactContent />
      <Footer />
    </>
  );
}

export default ContactHolding;