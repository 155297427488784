import React from 'react';
import '../../App.css';
import Footer from '../FooterHolding';
import HeroSectionProyects2 from '../HeroSectionProyects2';
import Navbar from '../NavbarHolding';

import Cards from '../CardsPro3';




function Proyecto2() {
  return (
    <>

      <Navbar />
      <HeroSectionProyects2 />
      <Cards />
      <Footer />
    </>
  );
}

export default Proyecto2;
