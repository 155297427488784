import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  const handleButtonClick = () => {
    window.open('https://www.linkedin.com/company/bccy-colombia/jobs/?viewAsMember=true', '_blank');
  };

  const handleButtonClickWebsite = () => {
    window.open('https://www.bccynewpower.com/home', '_blank');
  };

  const handleButtonYoutube = () => {
    window.open('https://www.youtube.com/channel/UCTBxxvzMkx8iEwuDEitBP8g', '_blank');
  };

  const handleButtonYoutubelink2 = () => {
    window.open('https://www.youtube.com/watch?v=xr3T8Zog8es', '_blank');
  };

  const handleButtonYoutubelink3 = () => {
    window.open('https://www.youtube.com/watch?v=0CNYvwO6k28', '_blank');
  };

  return (
    <div className='footer-container'>
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Sobre Nosotros</h2>
            <Link onClick={handleButtonClickWebsite}>Casa Matriz Web China</Link>
          </div>
          <div className='footer-link-items'>
            <h2>Contacto</h2>
            <li className='li-footer'><Link to='/contactc'>BCCY Cordoba</Link></li>
            <li className='li-footer'><Link to='/contacth'>BCCY Holding</Link></li>

          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Videos</h2>
            <li className='li-footer'><Link onClick={handleButtonYoutubelink2}>Proyecto Energía Solar</Link></li>
            <li className='li-footer'><Link onClick={handleButtonYoutubelink3}>Feliz Navidad BCCY</Link></li>
          </div>
          <div className='footer-link-items'>
            <h2>Redes Sociales</h2>
            <li className='li-footer'><Link onClick={handleButtonClick}>LinkedIn</Link></li>
            <li className='li-footer'><Link onClick={handleButtonYoutube}>YouTube</Link></li>
          </div>
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
              BCCY
              <i className='fas fa-leaf' />
            </Link>
          </div>
          <small className='website-rights'>BCCY © 2024</small>
          <div className='social-icons'>
            <Link
              className='social-icon-link youtube'
              onClick={handleButtonYoutube}
              target='_blank'
              rel="noopener noreferrer"
              aria-label='Youtube'
            >
              <i className='fab fa-youtube youtube-icon' />
            </Link>
            <Link
              className='social-icon-link  linkedin linkedin-icon'
              target='_blank'
              rel="noopener noreferrer"
              aria-label='LinkedIn'
              onClick={handleButtonClick}
            >
              <i className='fab fa-linkedin' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
