import React from 'react';
import '../../App.css';
import Footer from '../Footer';

import Navbar from '../Navbar';
import HeroSection1 from '../HeroSection1';

function Home1() {
  return (
    <>

      <Navbar />
      <HeroSection1 />
      <Footer />
    </>
  );
}

export default Home1;