import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import copy from 'clipboard-copy';

function Footer() {
  const handleButtonClick = () => {
    window.open('https://www.linkedin.com/company/bccy-colombia/jobs/?viewAsMember=true', '_blank');
  };

  const handleButtonYoutube = () => {
    window.open('https://www.youtube.com/channel/UCTBxxvzMkx8iEwuDEitBP8g', '_blank');
  };

  const handleClickCopy = (phoneNumber) => {
    copy(phoneNumber);
    alert(`Número ${phoneNumber} copiado al portapapeles`);
  };

  // const handleEmailClick = (email) => {
  //   window.location.href = `mailto:${email}`;
  //   copy(email);
  // };

  return (
    <div className='footer-container'>
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Contacto BCCY Colombia Holding</h2>
            <li className='li-footer' onClick={() => handleClickCopy('601-7569677')}>
              Bogotá 601-7569677
            </li>
            <li className='li-footer' onClick={() => handleClickCopy('3242422661')}>
              Medellín 3242422661
            </li>
            <li className='li-footer' onClick={() => handleClickCopy('3188430380')}>
              Barranquilla 3188430380
            </li>
            <li className='li-footer' onClick={() => handleClickCopy('3013539744')}>
              Cali 3013539744
            </li>
          </div>
          <div className='footer-link-items'>
            <h2>Trabaje con Nosotros</h2>
            <br />
            {/* <p className='p-correo' onClick={() => handleEmailClick('l.gomez@bccynewpower.com')}>l.gomez@bccynewpower.com</p> */}
            <li className='li-footer' onClick={() => handleClickCopy('3160267867')}>
            3160267867
            </li>
          </div>
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
              BCCY
              <i className='fas fa-leaf' />
            </Link>
          </div>
          <small className='website-rights'>BCCY © 2024</small>
          <div className='social-icons'>
            <Link
              className='social-icon-link youtube'
              onClick={handleButtonYoutube}
              target='_blank'
              rel='noopener noreferrer'
              aria-label='Youtube'
            >
              <i className='fab fa-youtube youtube-icon' />
            </Link>
            <Link
              className='social-icon-link  linkedin linkedin-icon'
              target='_blank'
              rel='noopener noreferrer'
              aria-label='LinkedIn'
              onClick={handleButtonClick}
            >
              <i className='fab fa-linkedin' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
