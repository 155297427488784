import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import swal from 'sweetalert'; // Importa SweetAlert

function HeroSectionHol() {
  const showNoVideoMessage = () => {
    swal('Sin video disponible', 'Por el momento no hay un video disponible.', 'info');
  };

  return (
    <div className='hero-container'>
      <video src='/videos/video-1.mp4' autoPlay loop muted />
      <h1>BCCY COLOMBIA HOLDING</h1>
      <p>Abogar por una economía baja en carbono y contribuir con energía limpia</p>
      <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={showNoVideoMessage} // Asigna la función de mostrar el mensaje al evento onClick
        >
          MIRAR VIDEO <i className='far fa-play-circle' />
        </Button>
      </div>
    </div>
  );
}

export default HeroSectionHol;
