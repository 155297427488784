import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import Image from 'react-bootstrap/Image';
import bccy1 from '../imagenes/bccy1.png';

function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => {
    setClick(false);
    setDropdown(false);
  };

  const handleDropdown = () => setDropdown(!dropdown);

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
            <Image src={bccy1} className='navbar-logo-image' alt='Logo' />
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                Inicio
              </Link>
            </li>
            <li className={`nav-item ${dropdown ? 'dropdown-active' : ''}`} onClick={handleDropdown}>
              <div className={`nav-links ${dropdown ? 'dropdown-active' : ''}`}>
                Contactenos <i className='fas fa-caret-down' />
              </div>
              {dropdown && (
                <ul className='dropdown-menu'>
                  <li>
                    <Link to='/contactc' onClick={closeMobileMenu}>
                      Córdoba
                    </Link>
                  </li>
                  <li>
                    <Link to='/contacth' onClick={closeMobileMenu}>
                      Holding
                    </Link>
                  </li>
                </ul>
              )}
            </li>

          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
