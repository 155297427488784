import React from 'react';
import '../../App.css';
import CardsHol from '../CardsHol';
import HeroSectionHol from '../HeroSectionHol';
import Footer from '../FooterHolding';
import NavbarHolding from '../NavbarHolding';

function Holding() {
  return (
    <>

      <NavbarHolding />

      <HeroSectionHol />
      <CardsHol />
      <Footer />
    </>
  );
}

export default Holding;
