import React from 'react';
import '../../App.css';
import Footer from '../FooterHolding';
import HeroSectionProyects2 from '../HeroSectionProyects3';
import Navbar from '../NavbarHolding';
import Cards from '../CardsPro4';




function Proyecto4() {
  return (
    <>

      <Navbar />
      <HeroSectionProyects2 />
      <Cards />
      <Footer />
    </>
  );
}

export default Proyecto4;
