import React from 'react';
import './App.css';
import Home from './components/pages/Cordoba';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Services from './components/pages/Services';
import Products from './components/pages/Products';
import SignUp from './components/pages/SignUp';
import Home1 from './components/pages/Home1';
import Contact from './components/pages/Contact';

import ContactH from './components/pages/ContactHolding';
import ContactC from './components/pages/ContactCordoba';


import Holding from './components/pages/Holding';
import Proyects1 from './components/pages/Proyecto1';
import Proyects2 from './components/pages/Proyecto2';

import Proyects3 from './components/pages/Proyecto3';
import Proyects4 from './components/pages/Proyecto4';
import ScrollToTop from 'react-router-scroll-top';

function App() {
  return (
    <>
      <Router>
      <ScrollToTop>
        <Switch>
          <Route path='/cordoba' exact component={Home}/>
          <Route path='/services' component={Services}/>
          <Route path='/products' component={Products}/>
          <Route path='/contact' component={Contact}/>
          <Route path='/contacth' component={ContactH}/>
          <Route path='/contactc' component={ContactC}/>
          <Route path='/sign-up' component={SignUp}/>
          <Route path='/holding' component={Holding}/>
          <Route path='/proyects1' component={Proyects1}/>
          <Route path='/proyects2' component={Proyects2}/>
          <Route path='/proyects3' component={Proyects3}/>

          <Route path='/proyects4' component={Proyects4}/>
          <Route path='/' component={Home1}/>

        </Switch>
    </ScrollToTop>
      </Router>
    </>
  );
}

export default App;
