import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function CardsPro1() {
  return (
    <div className='cards'>
      <h1 className='h1cards'>Datos Clave Pétalo de Córdoba II</h1>
      <div className='cards__paragraphs'>
          <ul>
              <li>Localización: municipio de Chinú, Córdoba</li>
              <li>Área del terreno: 33 ha</li>
              <li>Capacidad: 9.9 MWac (12.75 MWac)</li>
              <li>Producción: 25.1 GWh/año (P95)</li>
              <li>Punto de conexión: Subestación Chinú planta 34.5 kV</li>
              <li>Línea de conexión: 26 KM a 34.5 kV</li>
              <li>Paneles Solares: 22.568 módulos de 565 Wp JA solar bifaciales tipo N</li>
              <li>Inversores: 54 de 220 KW, HUAWEI</li>
              <li>Trackers: 217, SCHLETTER</li>
              <li>Entrada en Operación Comercial: 15 de Diciembre de 2023</li>
              <li>Estado: Operación comercial</li>
            </ul>
      </div>

      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
          <CardItem
              src='images/petalocorii1.png'
              text='Adecuación del terreno para instalación de la planta fotovoltaica'
              label='BCCY CÓRDOBA'
              path='/proyectos1'
            />
            <CardItem
              src='images/petalocorii2.jpg'
              text='Paneles ya instalados'
              label='BCCY CÓRDOBA'
              path='/services'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/petalocorii3.jpg'
              text='Vista aérea del parque solar Pétalo de Córdoba II 9.9 MWac'
              label='BCCY CÓRDOBA'
              // path='/services'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/petalocorii4.jpg'
              text='Mesa con 104 paneles en su máximo ángulo de giro'
              label='BCCY CÓRDOBA'
              path='/proyects1'
            />
            <CardItem
              src='images/petalocorii5.jpg'
              text='Paneles bifaciales con sistema de tracker en pruebas'
              label='BCCY CÓRDOBA'
              path='/services'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/petalocorii6.jpg'
              text='Inversor HUAWEI de 215 kW instalado'
              label='BCCY CÓRDOBA'
              // path='/services'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/petalocorii7.jpg'
              text='Unidad de control de trackers'
              label='BCCY CÓRDOBA'
              path='/proyects1'
            />
            <CardItem
              src='images/petalocorii8.jpg'
              text='Centro de transformación 800V/34,500V N°2'
              label='BCCY CÓRDOBA'
              path='/services'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/petalocorii9.jpg'
              text='Conexionado de los paneles fotovoltaicos a centro de transformación'
              label='BCCY CÓRDOBA'
              // path='/services'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/petalocorii10.jpg'
              text='Bahía de conexión de Pétalo de Córdoba II en subestación Chinú 34.5 kV'
              label='BCCY CÓRDOBA'
              path='/proyects1'
            />
            <CardItem
              src='images/petalocorii11.jpg'
              text='Centro de control en la planta fotovoltaica'
              label='BCCY CÓRDOBA'
              path='/services'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/petalocorii12.jpg'
              text='Información meteorológica en centro de control'
              label='BCCY CÓRDOBA'
              // path='/services'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/petalocorii13.jpg'
              text='Sistema de trackers'
              label='BCCY CÓRDOBA'
              path='/proyects1'
            />
            <CardItem
              src='images/petalocorii14.jpg'
              text='Estación meteorológica'
              label='BCCY CÓRDOBA'
              path='/services'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/petalocorii15.png'
              text='LÍnea de transmisión 34,5 kV que conecta la planta a la subestación Chinú de AFINIA'
              label='BCCY CÓRDOBA'
              // path='/services'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/petalocorii16.jpg'
              text='Fiesta de navidad para los niños de los cabildos indígenas Termoeléctrica y Algarrobos'
              label='BCCY CÓRDOBA'
              // path='/services'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/petalocorii17.jpg'
              text='Línea de transmisión en configuración semiaislada.'
              label='BCCY CÓRDOBA'
              path='/proyects1'
            />
            <CardItem
              src='images/petalocorii18.jpg'
              text='Linea de transmisión en configuración abierta'
              label='BCCY CÓRDOBA'
              path='/services'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/petalocorii19.jpg'
              text='Centro de transformación 6 MVA 34500/800 V, Gabinete de UPS de SSAA, foso de aceite y cámara de CCTV'
              label='BCCY CÓRDOBA'
            />
          </ul>



          <ul className='cards__items'>
            {/* <CardItem
              src='images/petalocorii20.jpg'
              text='Caja de media tensión del centro de transformación'
              label='BCCY CÓRDOBA'
              path='/proyects1'
              className='large-image'
            /> */}
            <CardItem
              src='images/petalocorii21.jpg'
              text='Cuarto de control, y operario técnico del parque.'
              label='BCCY CÓRDOBA'
              path='/services'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/petalocorii22.jpg'
              text='SCADA y Monitor 1 de CCTV'
              label='BCCY CÓRDOBA'
            />
          </ul><ul className='cards__items'>
            <CardItem
              src='images/petalocorii23.jpg'
              text='Pooling área (centro de seccionamiento, planta diesel, cuarto de control, estación meterológica y al fondo el poste de transición aéreo-subterránea'
              label='BCCY CÓRDOBA'
              path='/proyects1'
            />
            <CardItem
              src='images/petalocorii24.jpg'
              text='Celdas de entrada y salida del media tensión del centro de seccionamiento, (se aprecian los relés, pulsadores manuales del interruptor principal, testigos de presencia de tensión)'
              label='BCCY CÓRDOBA'
              path='/services'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/petalocorii25.jpg'
              text='Centro de seccionamiento, al fondo, campo fotovoltaico'
              label='BCCY CÓRDOBA'
              // path='/services'
            />
          </ul><ul className='cards__items'>
            <CardItem
              src='images/petalocorii26.jpg'
              text='Cuarto de control, al fondo, campo fotovoltaico'
              label='BCCY CÓRDOBA'
              path='/proyects1'
            />
            <CardItem
              src='images/petalocorii27.jpg'
              text='Estaciones de trabajo del SCADA y CCTV'
              label='BCCY CÓRDOBA'
              path='/services'
            />
          </ul>
          <ul className='cards__items'>
            {/* <CardItem
              src='images/petalocorii28.jpg'
              text='Estación de trabajo del CCTV'
              label='BCCY CÓRDOBA'
              // path='/services'
            /> */}
          </ul><ul className='cards__items'>
            <CardItem
              src='images/petalocorii29.jpg'
              text='Estación de trabajo del SCADA mostrando el registro diario de potencia generada'
              label='BCCY CÓRDOBA'
              path='/proyects1'
            />
            <CardItem
              src='images/petalocorii30.png'
              text='Vista aerea del parque fotovoltaico Pétalo de Córdoba II, imagen tomada para el canal de televisión CGTN'
              label='BCCY CÓRDOBA'
              path='/services'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/petalocorii31.jpg'
              text='Campo fotovoltaico, sistema de seguidores solares 1V en posición de defensa'
              label='BCCY CÓRDOBA'
              // path='/services'
            />
          </ul><ul className='cards__items'>
            {/* <CardItem
              src='images/petalocorii32.jpg'
              text='Realización segura de maniobras en las cuhillas de la transición aereo-subterráneo'
              label='BCCY CÓRDOBA'
              path='/proyects1'
            /> */}
            <CardItem
              src='images/petalocorii33.jpg'
              text='Operario con traje de 25 kCal realizando maniobras seguras en el compartimento de mantenimiento del inversor.'
              label='BCCY CÓRDOBA'
              path='/services'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/petalocorii34.png'
              text='Campo fotovoltáico'
              label='BCCY CÓRDOBA'
              // path='/services'
            />
          </ul><ul className='cards__items'>
            <CardItem
              src='images/petalocorii35.png'
              text='Reaización segura de maniobas en seccionador de línea del centro de transformación'
              label='BCCY CÓRDOBA'
              path='/proyects1'
            />
            <CardItem
              src='images/petalocorii36.jpg'
              text='Campo fotovoltaico, sistema de seguidor solar en posición de defensa'
              label='BCCY CÓRDOBA'
              path='/services'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/petalocorii37.jpg'
              text='Terminación de terminales tipo codo en celda de media tensión del centro de transformación.'
              label='BCCY CÓRDOBA'
              // path='/services'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default CardsPro1;
