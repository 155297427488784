import React from 'react';
import './Cards.css';
// import CardItem from './CardItem';

function CardsPro1() {
  return (
    <div className='cards'>
      <h1 className='h1cards'>Datos Clave Relleno Sanitario Los Pocitos - BARRANQUILLA</h1>
      <div className='cards__paragraphs'>
          <ul>
              <li>Localización: Municipio de Galapa (Atlántico)</li>
              <li>Área del terreno: 3000 m²</li>
              <li>Capacidad: 5310 kW (5 unidades)</li>
              <li>Producción estimada: 3612 GWh</li>
              <li>Punto de conexión: En relleno sanitario, línea 13.8 kV</li>
              <li>Línea de conexión: 1.3 km a 13.8 kV</li>
              <li>Equipos de generación: Generadores síncronos acoplados a un motor de combustión interna, marca JEMBACHER</li>
              <li>Entrada en Operación Comercial estimada: 14 de diciembre de 2024</li>
              <li>Estado: En construcción</li>
            </ul>
      </div>
{/*
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/positos1.jpeg'
              text='Adecuación del terreno para instalación de la planta fotovoltaica'
              label='BCCY CÓRDOBA'
            />
            <CardItem
              src='images/positos2.jpeg'
              text='Paneles ya instalados'
              label='BCCY CÓRDOBA'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/positos3.jpeg'
              text='Vista aérea del parque solar Pétalo de Córdoba II 9.9 MWac'
              label='BCCY CÓRDOBA'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/positos4.jpeg'
              text='Mesa con 104 paneles en su máximo ángulo de giro'
              label='BCCY CÓRDOBA'
            />
          </ul>
        </div>
      </div> */}
    </div>
  );
}

export default CardsPro1;
