import React from 'react';
import '../../App.css';
import Footer from '../FooterCordoba';
import HeroSectionProyects from '../HeroSectionProyects1';
import Navbar from '../Navbar';
import Cards from '../CardsPro2';




function Proyecto2() {
  return (
    <>

      <Navbar />
      <HeroSectionProyects />
      <Cards />
      <Footer />
    </>
  );
}

export default Proyecto2;
