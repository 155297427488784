import React from 'react';
import '../../App.css';
import Footer from '../FooterCordoba';
import Navbar from '../NavbarCordoba';
import HeroSectionProyects from '../HeroSectionProyects';
import Cards from '../CardsPro1';

function Proyecto1() {
  return (
    <>
      <Navbar />
      <HeroSectionProyects />
      <Cards />
      <Footer />
    </>
  );
}

export default Proyecto1;
